import React from 'react'


export const Loader = () => {
  return (
  <>
    
<div className='loaderContainer'>
    
    </div>
  </>
  
  )
}

export default Loader;

// https://stackabuse.com/how-to-create-a-loading-animation-in-react-from-scratch/